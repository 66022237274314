import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Globals } from "app/globals";

@Injectable({
  providedIn: "root",
})
export abstract class BaseService {
  constructor(public http: HttpClient, public globals: Globals) {}

  public model: string = "";
  public route: string = "";

  get(filters?: any) {
    return this.http.get<any[]>(`${this.globals.apiUrl}/${this.route}/get`, {
      params: { filters: JSON.stringify(filters || {}) },
    });
  }

  add(data: any) {
    return this.http.post<any>(`${this.globals.apiUrl}/${this.route}/add`, {
      [this.model]: data,
    });
  }

  update(data: any) {
    return this.http.post<any>(`${this.globals.apiUrl}/${this.route}/update`, {
      [this.model]: data,
    });
  }

  delete(id: string | number) {
    return this.http.post<any>(`${this.globals.apiUrl}/${this.route}/delete`, {
      id,
    });
  }
}
